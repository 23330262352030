'use client';

import { useColorTheme } from '@/hooks/use-color-theme';
import { Button } from '@/components/ui/button';
import { merge } from '@/utils/merge-classes';

export function ColorThemeSelector() {
  const [colorTheme, setColorTheme] = useColorTheme();

  return (
    <div className="flex gap-1 items-center justify-start">
      <Button
        size="icon"
        variant="outline"
        className={merge(
          'border-none flex items-center justify-center w-8 h-8 px-1 rounded-full',
          colorTheme === 'green'
        )}
        onClick={() => setColorTheme('green')}
      >
        <div className="flex gap-2">
          <div className="flex items-center bg-green-500 w-5 h-5 rounded-full">
            {(colorTheme === 'green' || !colorTheme) && (
              <span className="flex h-6 w-6 items-center justify-center rounded-full bg-[--theme-primary] ">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                >
                  <path
                    d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            )}
          </div>
          <div className="sr-only">Green color theme</div>
        </div>
      </Button>

      <Button
        size="icon"
        variant="outline"
        className={merge(
          'border-none flex items-center justify-center w-8 h-8 px-1 rounded-full',
          colorTheme === 'blue'
        )}
        onClick={() => setColorTheme('blue')}
      >
        <div className="flex gap-2">
          <div className="flex items-center bg-[--blue-500] w-5 h-5 rounded-full">
            {colorTheme === 'blue' && (
              <span className="flex h-6 w-6 items-center justify-center rounded-full bg-[--theme-primary]">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                >
                  <path
                    d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            )}
          </div>
          <div className="sr-only">Blue color theme</div>
        </div>
      </Button>

      <Button
        size="icon"
        variant="outline"
        className={merge(
          'border-none flex items-center justify-center w-8 h-8 px-1 rounded-full',
          colorTheme === 'orange'
        )}
        onClick={() => setColorTheme('orange')}
      >
        <div className="flex gap-2">
          <div className="flex items-center bg-orange-500 w-5 h-5 rounded-full">
            {colorTheme === 'orange' && (
              <span className="flex h-6 w-6 items-center justify-center rounded-full bg-[--theme-primary] ">
                <svg
                  width="15"
                  height="15"
                  viewBox="0 0 15 15"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 text-white"
                >
                  <path
                    d="M11.4669 3.72684C11.7558 3.91574 11.8369 4.30308 11.648 4.59198L7.39799 11.092C7.29783 11.2452 7.13556 11.3467 6.95402 11.3699C6.77247 11.3931 6.58989 11.3355 6.45446 11.2124L3.70446 8.71241C3.44905 8.48022 3.43023 8.08494 3.66242 7.82953C3.89461 7.57412 4.28989 7.55529 4.5453 7.78749L6.75292 9.79441L10.6018 3.90792C10.7907 3.61902 11.178 3.53795 11.4669 3.72684Z"
                    fill="currentColor"
                    fillRule="evenodd"
                    clipRule="evenodd"
                  ></path>
                </svg>
              </span>
            )}
          </div>
          <div className="sr-only">Orange color theme</div>
        </div>
      </Button>
    </div>
  );
}
