'use client';

import { useRef, useState, useEffect } from 'react';
import { useAuth } from '@/context/auth-context';
import ThemeSelector from '@/components/theme-selector';
import { ColorThemeSelector } from '@/components/color-theme-selector'; // Import ColorThemeSelector
import { usePathname } from 'next/navigation';
import Link from 'next/link';
import Full from '@/containers/full';
import Wide from '@/containers/wide';
import { Button } from '@/components/ui/button';
import { ChevronRight, Settings, Trash2 } from 'lucide-react';
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog';
import {
  Select,
  SelectTrigger,
  SelectValue,
  SelectContent,
  SelectItem,
} from '@/components/ui/select';
import { Input } from '@/components/ui/input';
import {
  getAuth,
  GoogleAuthProvider,
  GithubAuthProvider,
  EmailAuthProvider,
  reauthenticateWithPopup,
  reauthenticateWithCredential,
  deleteUser,
  updateProfile,
} from 'firebase/auth';
import { doc, updateDoc, deleteDoc } from 'firebase/firestore';
import { db } from '@/lib/firebase';
import { toast } from '@/hooks/use-toast';
import { useTheme } from 'next-themes';
import headerStyles from '../styles/components/Header.module.css';
import { useRouter } from 'next/navigation';

import styles from '../styles/components/Logo.module.css';
import { getFunctions, httpsCallable } from 'firebase/functions';

const Header = () => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [isOverlayVisible, setOverlayVisible] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);
  const buttonRef = useRef<HTMLButtonElement>(null);
  const themeSelectorRef = useRef<HTMLDivElement>(null);
  const [isSettingsDialogOpen, setIsSettingsDialogOpen] =
    useState(false);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const router = useRouter();
  const pathname = usePathname() || '';
  const { user, logout, setUser, loading } = useAuth();
  const { theme, setTheme } = useTheme();

  const [userName, setUserName] = useState(user?.displayName || '');
  const [username, setUsername] = useState(user?.username || '');
  const [selectedTheme, setSelectedTheme] = useState(
    user?.theme || 'system'
  );
  const [isCheckingUsername, setIsCheckingUsername] = useState(false);
  const [usernameValidationState, setUsernameValidationState] =
    useState<'idle' | 'valid' | 'invalid'>('idle');

  useEffect(() => {
    if (user) {
      setUserName(user.displayName || '');
      setUsername(user.username || '');
      setSelectedTheme(user.theme || 'system');
      setTheme(user.theme || 'system');
    }
  }, [user, setTheme]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 768) {
        setOverlayVisible(false);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const toggleOverlay = (event: React.MouseEvent) => {
    event.stopPropagation();
    setOverlayVisible((prev) => !prev);
  };
  const validateUsername = async (): Promise<boolean> => {
    if (!username.trim()) {
      setUsernameValidationState('invalid');
      toast({
        title: 'Invalid Username',
        description: 'Username cannot be empty.',
        variant: 'destructive',
      });
      return false;
    }

    if (!/^[a-zA-Z0-9_]+$/.test(username)) {
      setUsernameValidationState('invalid');
      toast({
        title: 'Invalid Username',
        description:
          'Usernames can only contain letters, numbers, and underscores.',
        variant: 'destructive',
      });
      return false;
    }

    setIsCheckingUsername(true);
    try {
      const functions = getFunctions(undefined, 'europe-west3');
      const validateUsernameFn = httpsCallable<
        { username: string },
        { available: boolean }
      >(functions, 'validateUsername');
      const result = await validateUsernameFn({ username });

      setIsCheckingUsername(false);

      if (result.data.available) {
        setUsernameValidationState('valid');
        return true;
      } else {
        setUsernameValidationState('invalid');
        toast({
          title: 'Username Taken',
          description: 'Please choose a different username.',
          variant: 'destructive',
        });
        return false;
      }
    } catch (error) {
      console.error('Error validating username:', error);
      setIsCheckingUsername(false);
      setUsernameValidationState('invalid');
      toast({
        title: 'Error',
        description: 'Failed to validate username. Try again later.',
        variant: 'destructive',
      });
      return false;
    }
  };

  const handleUpdateSettings = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      toast({
        title: 'Error',
        description: 'User not authenticated.',
        variant: 'destructive',
      });
      return;
    }

    if (username !== user?.username) {
      const isUsernameValid = await validateUsername();
      if (!isUsernameValid) return;
    }

    try {
      const oldUsername = user?.username;
      const userDocRef = doc(db, 'users', currentUser.uid);
      await updateDoc(userDocRef, {
        displayName: userName,
        username,
        theme: selectedTheme,
      });

      setUser((prev) =>
        prev
          ? {
              ...prev,
              displayName: userName,
              username,
              theme: selectedTheme,
            }
          : prev
      );

      if (username !== oldUsername) {
        const currentPath = pathname.split('/').slice(2).join('/');
        const newPath = `/${username}/${currentPath}`;
        router.push(newPath);
      }

      setTheme(selectedTheme);
      toast({
        title: 'Settings Updated',
        description: 'Your user settings have been updated.',
      });
      setIsSettingsDialogOpen(false);
    } catch (error) {
      console.error('Error updating settings:', error);
      toast({
        title: 'Error',
        description: 'Failed to update settings.',
        variant: 'destructive',
      });
    }
  };

  const usernameInputClassName =
    usernameValidationState === 'valid'
      ? 'border-green-500'
      : usernameValidationState === 'invalid'
      ? 'border-red-500'
      : 'border-border';

  const handleDeleteAccount = async () => {
    const auth = getAuth();
    const currentUser = auth.currentUser;

    if (!currentUser) {
      toast({
        title: 'Error',
        description: 'User not authenticated.',
        variant: 'destructive',
      });
      return;
    }

    try {
      await attemptReauthentication(currentUser);
      const userDocRef = doc(db, 'users', currentUser.uid);

      setDeleteDialogOpen(false);
      setIsSettingsDialogOpen(false);

      await deleteDoc(userDocRef);
      await deleteUser(currentUser);

      toast({
        title: 'Account Deleted',
        description: 'Your account has been deleted.',
      });

      router.push('/');
    } catch (error) {
      console.error('Error deleting account:', error);

      let errorMessage = 'Failed to delete account.';
      if ((error as any).code === 'auth/requires-recent-login') {
        errorMessage =
          'Please re-authenticate to delete your account.';
      }

      toast({
        title: 'Error',
        description: errorMessage,
        variant: 'destructive',
      });
    }
  };

  const attemptReauthentication = async (user: any) => {
    const auth = getAuth();

    // Determine provider from providerData
    const providerId = user.providerData[0].providerId;

    if (providerId === 'google.com') {
      const googleProvider = new GoogleAuthProvider();
      await reauthenticateWithPopup(user, googleProvider);
    } else if (providerId === 'github.com') {
      const githubProvider = new GithubAuthProvider();
      await reauthenticateWithPopup(user, githubProvider);
    } else if (providerId === 'password') {
      const email = user.email;
      const password = prompt(
        'Please enter your password to confirm deletion:'
      );
      if (!password)
        throw new Error('Password required for re-authentication.');
      const credential = EmailAuthProvider.credential(
        email,
        password
      );
      await reauthenticateWithCredential(user, credential);
    } else {
      throw new Error('Unknown authentication provider.');
    }
  };

  const headerContent = (
    <div className="py-1 w-full flex items-center justify-between">
      <div className="flex md:gap-10 lg:gap-14 items-center min-h-[40px]">
        <Link href={user ? `/${username}` : '/'} scroll={false}>
          <span className="flex items-center">
            <span className={styles.logo}>Redirectory</span>
          </span>
        </Link>

        {!loading && !user && pathname.startsWith(`/`) && (
          <nav className="hidden md:flex">
            <ul className="flex md:gap-3 lg:gap-10 items-center">
              {['/story', '/resources', '/pricing', '/support'].map(
                (link, index) => (
                  <li key={index}>
                    <Link
                      href={link}
                      scroll={false}
                      className={`text-sm text-foreground/60 hover:text-inverted ${
                        pathname.startsWith(link) &&
                        `text-inverted bg-bottom-border border-b-[1px] border-foreground/100`
                      }`}
                    >
                      {link.replace('/', '').charAt(0).toUpperCase() +
                        link.slice(2)}
                    </Link>
                  </li>
                )
              )}
            </ul>
          </nav>
        )}

        {user &&
          user.displayName &&
          !pathname.startsWith(`/${username}`) && (
            <Link
              href={`/${username}`}
              scroll={false}
              className="flex items-center gap-2"
            >
              <div className="w-2 h-2 bg-[--ui-primary] rounded-full" />
              <span className="flex text-sm text-foreground/60 hover:text-inverted">
                {user.displayName}&apos;s directories
              </span>
            </Link>
          )}

        {user &&
          user.displayName &&
          pathname.startsWith(`/${username}`) && (
            <div className="relative left-[-20px] flex items-center gap-2">
              <div className="w-2 h-2 bg-[--ui-primary] rounded-full" />
              <span className="text-sm text-foreground ">
                {user.displayName}&apos;s directories
              </span>
            </div>
          )}
      </div>
      <div className="hidden gap-3 md:flex md:items-center">
        {user ? (
          <>
            <Dialog
              open={isSettingsDialogOpen}
              onOpenChange={setIsSettingsDialogOpen}
            >
              <DialogTrigger
                onClick={() => setIsSettingsDialogOpen(true)}
                className="inline-flex items-center justify-center duration-300 whitespace-nowrap rounded-md text-sm font-medium ring-offset-white transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-slate-950 focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 dark:ring-offset-slate-950 dark:focus-visible:ring-slate-300 border border-border bg-white hover:bg-accent hover:text-slate-900 dark:bg-slate-950 dark:hover:bg-accent dark:hover:text-slate-50 h-10 px-4 py-2"
              >
                <Settings className="mr-2 h-4 w-4" /> Settings
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Settings</DialogTitle>
                  <DialogDescription>
                    Update your user information and preferences.
                  </DialogDescription>
                </DialogHeader>
                <div className="space-y-4">
                  <div>
                    <label className="block mb-2 mt-4 text-sm font-medium text-muted-foreground">
                      Name
                    </label>
                    <Input
                      type="text"
                      value={userName}
                      onChange={(e) => setUserName(e.target.value)}
                      placeholder="Enter your name"
                    />
                  </div>

                  <div>
                    <label className="block text-sm mb-4 mt-4 font-medium text-muted-foreground">
                      Username
                    </label>
                    <Input
                      type="text"
                      value={username}
                      onChange={(e) => {
                        setUsername(e.target.value);
                        setUsernameValidationState('idle');
                      }}
                      className={usernameInputClassName}
                    />
                  </div>
                  <div>
                    <label className="block mb-2 text-sm font-medium text-muted-foreground">
                      Theme
                    </label>
                    <Select
                      value={theme}
                      onValueChange={(value) => {
                        setSelectedTheme(value);
                        setTheme(value);
                      }}
                    >
                      <SelectTrigger>
                        <SelectValue />
                      </SelectTrigger>
                      <SelectContent className="border-border shadow-none">
                        <SelectItem value="light">Light</SelectItem>
                        <SelectItem value="dark">Dark</SelectItem>
                        <SelectItem value="system">System</SelectItem>
                      </SelectContent>
                    </Select>
                  </div>
                  <div className="flex gap-3 items-center justify-between">
                    <label className="block text-sm font-medium text-muted-foreground">
                      Accent Color
                    </label>
                    <ColorThemeSelector />
                  </div>
                  <div className="flex gap-3 items-center justify-between">
                    <label className="block text-sm font-medium text-muted-foreground">
                      Email
                    </label>
                    <span className="text-sm text-foreground/80">
                      {user.email}
                    </span>
                  </div>
                  <div className="border-[0.5px] border-border"></div>

                  {/* Delete Account Section */}
                  <Button
                    variant="destructiveLink"
                    onClick={() => setDeleteDialogOpen(true)}
                    className="mt-4 p-0"
                  >
                    <Trash2 className="mr-2 h-4 w-4" /> Delete Account
                  </Button>

                  <Button
                    onClick={handleUpdateSettings}
                    className="w-full"
                  >
                    Save Changes
                  </Button>

                  {/* Confirm Delete Account Dialog */}
                  <Dialog
                    open={isDeleteDialogOpen}
                    onOpenChange={setDeleteDialogOpen}
                  >
                    <DialogContent>
                      <DialogHeader>
                        <DialogTitle>
                          Confirm Account Deletion
                        </DialogTitle>
                        <DialogDescription>
                          Are you sure you want to delete your
                          account? This action is irreversible, and
                          all of your data will be lost.
                        </DialogDescription>
                      </DialogHeader>
                      <div className="flex justify-end gap-4 mt-6">
                        <Button
                          variant="primary"
                          onClick={() => setDeleteDialogOpen(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          variant="destructive"
                          onClick={handleDeleteAccount}
                        >
                          Delete Account
                        </Button>
                      </div>
                    </DialogContent>
                  </Dialog>
                </div>
              </DialogContent>
            </Dialog>

            <Button
              variant="outline"
              onClick={() => {
                logout();
                toast({
                  title: 'Logged out',
                  description:
                    'You have been logged out. See you soon.',
                });
              }}
            >
              Log Out
            </Button>
          </>
        ) : (
          <>
            <div ref={themeSelectorRef}>
              <ThemeSelector />
            </div>
            <Button variant="outline" asChild>
              <Link href="/login" scroll={false}>
                Log In
              </Link>
            </Button>
            <Button asChild>
              <Link href="/signup" scroll={false}>
                Sign Up <ChevronRight className="ml-1 h-4 w-4" />
              </Link>
            </Button>
          </>
        )}
      </div>
    </div>
  );

  return (
    <div className="relative">
      <header
        className={`pt-4 sticky top-0 transition duration-300 z-10 ${
          isScrolled ? 'border-b bg-card' : ''
        } ${
          pathname !== '/' && !pathname.startsWith(`${username}`)
            ? 'pt-0 bg-card'
            : ''
        }`}
      >
        {pathname === '/' || pathname.startsWith(`${username}`) ? (
          <Wide>{headerContent}</Wide>
        ) : (
          <Full>{headerContent}</Full>
        )}
      </header>

      <button
        ref={buttonRef}
        onClick={toggleOverlay}
        className={headerStyles.menuButton}
        aria-label="Menu"
      >
        {isOverlayVisible ? (
          <svg
            className={headerStyles.menuSvg}
            width="24"
            height="24"
            viewBox="0 0 0 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M18 6 6 18" />
            <path d="m6 6 12 12" />
          </svg>
        ) : (
          <svg
            className={headerStyles.menuSvg}
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="4" x2="20" y1="12" y2="12" />
            <line x1="4" x2="20" y1="6" y2="6" />
            <line x1="4" x2="20" y1="18" y2="18" />
          </svg>
        )}
      </button>

      {isOverlayVisible && (
        <div
          ref={menuRef}
          className={`flex flex-col bg-card border-border border-l gap-10 ${
            headerStyles.overlay
          } ${isOverlayVisible ? headerStyles.active : ''}`}
        >
          <nav className={headerStyles.mobileNav}>
            <ul className={headerStyles.mobileUl}>
              <li
                className={`${headerStyles.mobileLi} ${
                  pathname === '/' && `${headerStyles.activeLink}`
                }`}
              >
                <Link href="/story" scroll={false}>
                  Story
                </Link>
              </li>
              <li
                className={`${headerStyles.mobileLi} ${
                  pathname.startsWith('/resources') &&
                  `${headerStyles.activeLink}`
                }`}
              >
                <Link href="/resources" scroll={false}>
                  Resources
                </Link>
              </li>
              <li
                className={`${headerStyles.mobileLi} ${
                  pathname.startsWith('/pricing') &&
                  `${headerStyles.activeLink}`
                }`}
              >
                <Link href="/pricing" scroll={false}>
                  Pricing
                </Link>
              </li>
              <li
                className={`${headerStyles.mobileLi} ${
                  pathname.startsWith('/support') &&
                  `${headerStyles.activeLink}`
                }`}
              >
                <Link href="/support" scroll={false}>
                  Support
                </Link>
              </li>
            </ul>
          </nav>
        </div>
      )}
    </div>
  );
};

export default Header;
